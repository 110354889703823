<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'GlueSlider',

  components: {
    Swiper,
    SwiperSlide,

  },

  props: {
    devise: { type: Object },
    autoplay: {
      type: Boolean,
      default: false,
    },
    // Allowed values are 'arrows-bottom', 'block'
    controlStyle: {
      type: String,
      default: 'block',
    },
    showControls: {
      type: Boolean,
      default: false,
    },
    showNavigation: {
      type: Boolean,
      default: false,
    },
    // Allowed values are 'blue'. Default: white
    arrowColor: {
      type: String,
      default: 'white',
    },
    numberOfSlides: {
      type: [Number, Array],
      default: 1,
    },
  },

  data() {
    return {
      swiper: null,
    }
  },

  computed: {
    showTopBorder() {
      return this.devise && this.devise.showTopBorder
        ? this.devise.showTopBorder.checked
        : false
    },
    showBottomBorder() {
      return this.devise && this.devise.showBottomBorder
        ? this.devise.showBottomBorder.checked
        : false
    },
  },

  mounted() {
    this.updateOnInit()
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },
  },
}
</script>

<template>
  <div v-if="devise" class="relative" :class="{ 'swiper-no-swiping': devise.slice.elements.length < 2 }">
    <Swiper
      ref="mySwiper" :slides-per-view="1" :loop="devise.slice.elements.length > 1" :centered-slides="true"
      class="clipping-slider border-white" :class="{
        'border-t-10': showTopBorder,
        'border-b-10': showBottomBorder,
      }"
    >
      <SwiperSlide v-for="(slice, key) in devise.slice.elements" :key="key">
        <VoixSlice :slice="slice" :slice-index="key" />
      </SwiperSlide>
    </Swiper>

    <div
      v-if="devise.slice.elements.length > 1"
      class="absolute z-30 bottom-0 mb-4 lg:inset-0 pointer-events-none flex lg:justify-between items-center"
    >
      <button
        class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 left-0 ml-10 -mt-8 justify-center items-center"
        @click="prev"
      >
        <div class="slider-button text-white">
          <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </button>
      <button
        class="pointer-events-auto flex lg:absolute lg:z-50 top-0 bottom-0 right-0 mr-8 -mt-8 justify-center items-center"
        @click="next"
      >
        <div class="slider-button text-white">
          <svg
            class="w-7 h-7 transform rotate-180" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
